import React from 'react';
// Material UI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// Hooks
import { IsDateWithinRange } from '../../../../../../hooks/dateUtils';

function ProductNamePrice(props) {
  // Valor booleano si es temporada de acuaticos
  const isWithinRange = IsDateWithinRange();
  console.log(isWithinRange);

  const price = Number(props.price);
  const formattedPrice = isNaN(price) ? '-' : price.toLocaleString('en-US').replace(',', '.');

  return (
    <Grid container className="card-text-container" alignItems="center" justifyContent="space-between">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" className="card-title">
          {props.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography className="card-disponible-txt" variant="h6" color="textSecondary" sx={{ wordSpacing: '-3px' }}>
          {props.disponibletxt ? props.disponibletxt : '⠀'}
        </Typography>
      </Grid>

      {props.price !== null && (
        <Grid item xs={6} sm={6} md={6} lg={6}>
          {props.filtro.includes('acuaticos') ? (
            isWithinRange ? (
              <Typography className="card-price" variant="h6" color="textSecondary" sx={{ wordSpacing: '-3px' }}>
                $ {formattedPrice}
              </Typography>
            ) : (
              <Typography className="card-price" variant="h6" color="textSecondary" sx={{ wordSpacing: '-3px' }}>
                $ -
              </Typography>
            )
          ) : (
            <Typography className="card-price" variant="h6" color="textSecondary" sx={{ wordSpacing: '-3px' }}>
              $ {formattedPrice}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default ProductNamePrice;
